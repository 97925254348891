<template>
  <div>
    <div v-show="!showCheckout">
      <slot name="before-table"></slot>
      <dedicated-number-list
        :price="dedicatedNumberAppServiceItemPrice"
        :dedicated-numbers="dedicatedNumbers"
        @buy="buyDedicatedNumber"/>
    </div>

    <div v-show="showCheckout">
      <go-back-arrow
        divider
        @back="getBackFromCheckout()"/>

      <dedicated-number-payment-checkout
        ref="paymentCheckout"
        :caller-id="{ phoneInternational: numberToBuy }"
        :on-payment-intent-callback="sentPaymentIntent">
      </dedicated-number-payment-checkout>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

// Components
import CallerIdListCreateOrEditFromDedicatedNumberList
from '@/views/modules/sender-ids/caller-id/CallerIdListCreateOrEditFromDedicatedNumberList.vue';
import GoBackArrow from '@/views/modules/_components/GoBackArrow.vue';
import DedicatedNumberPaymentCheckout from '@/views/modules/sender-ids/caller-id/_components/DedicatedNumberPaymentCheckout.vue';

// Mixins
import dedicatedNumberAppServiceMixin
from '@/views/modules/sender-ids/caller-id/_mixins/dedicated-number-app-service.mixin';
import createDedicatedNumberMixin
from '@/views/modules/sender-ids/caller-id/_mixins/create-dedicated-number.mixin';

/**
 * Component to create or edit caller IDs from dedicated number
 *
 * @module views/modules/sender-ids/caller-id/CallerIdListCreateOrEditFromDedicatedNumber
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-prop {string[]} dedicatedNumbers - dedicated numbers info
 * @vue-event {void} fetchData - fetch component data
 * @vue-event {void} getBackFromCheckout - show again the checkout for dedicated number
 * @vue-event {void} paymentIntent - make a payment intent for buy a dedicated number
 */
export default {
  name: 'CallerIdListCreateOrEditFromDedicatedNumber',
  i18n: {
    messages: {
      en: {
        AppCheckoutNameInputPlaceholder: 'Name to identify this dedicated number',
        CompleteYourAccountDetailsToPay: 'Complete your account details to make the payment',
        SubscribeToDedicatedNumber: 'Subscribe to dedicated number',
      },
    },
  },
  components: {
    DedicatedNumberList: CallerIdListCreateOrEditFromDedicatedNumberList,
    GoBackArrow,
    DedicatedNumberPaymentCheckout,
  },
  mixins: [dedicatedNumberAppServiceMixin, createDedicatedNumberMixin],
  props: {
    dedicatedNumbers: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      getTenantFlag: 'auth/getTenantFlag',
    }),
  },
  created() {
    this.fetchData();

    this.$emit('update-modal-title', this.$t('SubscribeToDedicatedNumber'));
  },
  methods: {
    async fetchData() {
      await this.getDedicatedNumberAppService();
    },
    getBackFromCheckout() {
      this.numberToBuy = '';
      this.showCheckout = false;
    },
  },
};
</script>

<style scoped>

</style>
