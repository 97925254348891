<template>
  <div>
    <span
      class="flex items-center cursor-pointer"
      @click.prevent="$emit('back')">
      <a
        href="#"
        class="flex items center link-plain">
        <feather-icon
          icon="ArrowLeftIcon"
          svgClasses="w-6 h-6"/>
        <span class="ml-1 font-black">{{ $t('Text') }}</span>
      </a>
    </span>

    <vs-divider/>
  </div>
</template>

<script>
/**
 * Go back link button
 *
 * @module views/modules/components/GoBackArrow
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-prop {boolean} divider - indicate if show or not a divider
 */
export default {
  name: 'GoBackArrow',
  i18n: {
    messages: {
      en: {
        Text: 'Go back',
      },
    },
  },
  props: {
    divider: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

<style scoped>

</style>
