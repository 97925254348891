<template>
  <div class="vx-row items-stretch mt-5">
    <app-loading
      :active="showAppLoading && !preventAppLoading"/>

    <div
      v-for="(appServiceItem, index) in appServiceItems"
      :key="index"
      class="vx-col w-full md:w-1/3 xl:w-1/4 mt-5"
      :class="index > 3 ? 'md:mt-5' : 'md:mt-0'">
      <card-info
        :app-service-item="appServiceItem.appServiceItem"
        :caller-id="appServiceItem.callerId"
        :payment-methods="paymentMethods"
        :dedicated-number-selected="isDedicatedNumberSelected(appServiceItem.callerId._id)"
        @updated-payment-method="onUpdatedApServiceItem()"
        @pay="onPayDedicatedNumber(appServiceItem.callerId)"/>
    </div>

    <div
      class="vx-col w-full md:w-1/3 xl:w-1/4 mt-5"
      :class="appServiceItems.length > 3 ? 'xl:mt-5' : 'xl:mt-0'">
      <add-new-item-card
        :text="$t('AddNewText')"
        @add="showDedicatedNumbersListModal = true"
      />
    </div>

    <vs-popup
      :title="$t('GetNewDedicatedNumberModalTitle')"
      :active.sync="showDedicatedNumbersListModal"
      v-modal-no-scroll-body>
      <transition name="zoom-fade">
        <caller-id-list-create-or-edit-from-dedicated-number
          v-if="showDedicatedNumbersListModal"
          :entity="$enums.Entity.CALLER_ID"
          :operation="$enums.Operation.CREATE"
          :dedicated-numbers="[]"
          @saved="onSubscribedToDedicatedNumber()">
        </caller-id-list-create-or-edit-from-dedicated-number>
      </transition>
    </vs-popup>

    <dedicated-number-payment-checkout-modal
      v-if="callerIdToPay"
      v-model="activePaymentDedicatedNumberModal"
      :caller-id="callerIdToPay"
      @payment="onUpdatedApServiceItem()"/>
  </div>
</template>

<script>

import { registerSocketEvent, unregisterSocketEvent } from '@/socket';

// Components
import AddNewItemCard from '@/views/_components/AddNewItemCard.vue';
import UserAccountOverviewDedicatedNumbersCardInfo
from '@/views/modules/user/user-purchases/user-my-purchases/my-dedicated-numbers/UserMyPurchasesDedicatedNumbersCardInfo.vue';
import AppLoading from '@/views/_components/AppLoading.vue';
import CallerIdListCreateOrEditFromDedicatedNumber
from '@/views/modules/sender-ids/caller-id/CallerIdListCreateOrEditFromDedicatedNumber.vue';
import DedicatedNumberPaymentCheckoutModal
from '@/views/modules/sender-ids/caller-id/_components/DedicatedNumberPaymentCheckoutModal.vue';

// Services
import tenantService from '@/api/modules/tenant.service';

/**
 * Component to show user dedicated numbers subscriptions
 *
 * @module views/modules/user/user-purchases/user-my-purchases/my-dedicated-numbers/UserMyPurchasesDedicatedNumbers
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-data {Array<Object>} [appServiceItems=[]] - app service item to show
 * @vue-data {Array<Object>} [paymentMethods=[]] - tenant payment methods
 * @vue-data {boolean} [showDedicatedNumbersListModal=false] -
 * indicate if show or not the dedicated numbers list modal
 * @vue-data {boolean} [activePaymentDedicatedNumberModal=false] -
 * indicate if show or not the payment dedicated number modal
 * @vue-data {Object | null} [callerIdToPay=null] - the caller id selected to pay
 * @vue-events {void} fetchData - fetch initial component data
 * @vue-events {void} getPaymentMethods - get tenant payment methods
 * @vue-events {void} getAppServiceItems - get tenant app service items
 * @vue-events {void} onSubscribedToDedicatedNumber - called on subscribed to dedicated number
 * @vue-events {void} onPayDedicatedNumber - called to pay a dedicated number
 * @vue-events {void} onUpdatedApServiceItem - called on updated a tenant app service item
 */
export default {
  name: 'UserMyPurchasesDedicatedNumbers',
  i18n: {
    messages: {
      en: {
        AddNewText: 'Get a new dedicated number',
        GetNewDedicatedNumberModalTitle: 'Subscribe to dedicated number',
      },
    },
  },
  components: {
    AddNewItemCard,
    CardInfo: UserAccountOverviewDedicatedNumbersCardInfo,
    AppLoading,
    CallerIdListCreateOrEditFromDedicatedNumber,
    DedicatedNumberPaymentCheckoutModal,
  },
  data() {
    return {
      socketEventsListenerId: 'update-my-purchase-dedicated-number',
      appServiceItems: [],
      paymentMethods: [],
      showDedicatedNumbersListModal: false,
      activePaymentDedicatedNumberModal: false,
      callerIdToPay: null,
      dedicatedNumberSelected: '',
    };
  },
  mounted() {
    registerSocketEvent(this.$enums.SocketEvents.CALLER_ID_UPDATED, async () => {
      await this.getAppServiceItems();
    }, this.socketEventsListenerId,
    true);
  },
  beforeDestroy() {
    unregisterSocketEvent(
      this.$enums.SocketEvents.CALLER_ID_UPDATED,
      this.socketEventsListenerId,
    );
  },
  created() {
    this.dedicatedNumberSelected = this.$route.query.id;

    if (this.$mq === this.$enums.mqBreakpoints.DESKTOP) {
      window.history.replaceState({}, null, '/purchases/my-purchases/my-dedicated-numbers');
    }
    this.fetchData();
  },
  methods: {
    async fetchData() {
      this.showAppLoading = true;
      await this.getPaymentMethods();
      await this.getAppServiceItems();
      this.showAppLoading = false;
    },
    async getPaymentMethods() {
      const resp = await tenantService.getPaymentMethods();
      this.paymentMethods = resp.data ? resp.data : [];
    },
    async getAppServiceItems() {
      const resp = await tenantService.getAppServiceItems();
      this.appServiceItems = resp.data ? resp.data : [];
    },
    async onSubscribedToDedicatedNumber() {
      this.showDedicatedNumbersListModal = false;
      this.showAppLoading = true;
      await this.getAppServiceItems();
      this.showAppLoading = false;
    },
    onPayDedicatedNumber(callerId) {
      this.callerIdToPay = callerId;
      this.activePaymentDedicatedNumberModal = true;
    },
    async onUpdatedApServiceItem() {
      this.showAppLoading = true;
      await this.getAppServiceItems();
      this.showAppLoading = false;
    },
    isDedicatedNumberSelected(callerId) {
      return callerId === this.dedicatedNumberSelected;
    },
  },
};
</script>

<style scoped>

</style>
