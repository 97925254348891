<template>
  <vs-popup
    :title="$t('Title', { number: callerId.phoneInternational })"
    :active.sync="showModal"
    v-modal-no-scroll-body>
    <transition name="zoom-fade">
      <div v-if="showModal">
        <app-loading
          :active="showAppLoading && !preventAppLoading"/>

        <label>{{ $t('SelectLabel') }}</label>
        <v-select-payment-method
          v-model="selectedPaymentMethod"
          :payment-methods="paymentMethods"
          append-to-body>
        </v-select-payment-method>

        <base-form-footer-action-buttons
          @save="updatePaymentMethod()"
          @cancel="showModal = false">
        </base-form-footer-action-buttons>
      </div>
    </transition>
  </vs-popup>
</template>

<script>
// Components
import VSelectPaymentMethod from '@/views/modules/_components/VSelectPaymentMethod.vue';
import baseFormFooterActionButtons from '@/views/modules/_components/BaseFormFooterActionButtons.vue';
import AppLoading from '@/views/_components/AppLoading.vue';

// Services
import tenantService from '@/api/modules/tenant.service';

/**
 * Modal to update a payment method for a tenant app service item
 *
 * @module views/modules/user/user-purchases/user-my-purchases/my-dedicated-numbers/UserMyPurchasesDedicatedNumbersUpdatePaymentMethodModal
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-prop {boolean} value - indicate if show or not the modal
 * @vue-prop {Object} paymentMethod - selected payment method
 * @vue-prop {Array<Object>} paymentMethods - tenant payment methods
 * @vue-prop {Object} callerId - the caller id associated to tenant app service item
 * @vue-data {boolean} [showModal=value] - indicate if show modal or no
 * @vue-data {Object} selectedPaymentMethod - The selected local payment method
 * @vue-event {void} updatePaymentMethod - called to update the payment method
 */
export default {
  name: 'UserMyPurchasesDedicatedNumbersUpdatePaymentMethodModal',
  i18n: {
    messages: {
      en: {
        SelectLabel: 'Select the payment method',
        Title: 'Update payment method ({number})',
        $NotificationSuccess: {
          Title: 'Payment method update',
          Msg: 'Payment method updated successfully',
        },
      },
    },
  },
  components: {
    VSelectPaymentMethod,
    baseFormFooterActionButtons,
    AppLoading,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    paymentMethod: {
      type: Object,
      required: true,
    },
    paymentMethods: {
      type: Array,
      required: true,
    },
    callerId: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showModal: this.value,
      selectedPaymentMethod: this.paymentMethod,
    };
  },
  watch: {
    value(val) {
      this.showModal = val;
    },
    showModal(val) {
      this.$emit('input', val);

      if (val) {
        this.selectedPaymentMethod = this.paymentMethod;
      }
    },
  },
  methods: {
    async updatePaymentMethod() {
      this.showAppLoading = true;
      await tenantService.updateAppServiceItemDefaultPaymentMethod(
        this.callerId.id,
        this.selectedPaymentMethod.id,
      );
      this.showAppLoading = false;

      this.$showSuccessActionNotification({
        title: this.$t('$NotificationSuccess.Title'),
        text: this.$t('$NotificationSuccess.Msg'),
      });

      this.$emit('updated');
      this.showModal = false;
    },
  },
};
</script>

<style scoped>

</style>
