<template>
  <vx-card
    no-shadow
    card-border
    :style=dedicatedNumberSelectedColor>
    <div>
      <div class="text-center">
        <h5 class="font-bold text-center">
          ({{ callerId.phoneInternational }})
        </h5>
        <small class="font-bold text-center">
          {{ callerId.name }}
        </small>
      </div>
    </div>

    <vs-divider/>

    <div>
      <div class="flex justify-center items-center">
        <a
          href="#"
          :class="[
            canUpdatePaymentMethod
            ? 'link-decorate'
            : 'pointer-events-none text-black font-bold'
          ]"
          @click.prevent="showUpdatePaymentMethodModal = true">
          <i
            class="fab mr-1"
            :class="`fa-cc-${cardBrandIcon}`"></i>
          <span>
            •••• {{ appServiceItemPaymentMethodLast4 }}
              <span v-show="appServiceItemPaymentMethodIsTheDefault">
                ({{ $t('$General.Default') | lowercase }})
              </span>
          </span>
        </a>
      </div>

      <div class="text-center my-6" style="min-height: 65px">
        <h5 class="font-semibold">
          {{ billingLabel | sentencecase }}
        </h5>

        <div v-if="isToCancelNumber || isCanceledDedicatedNumber"
          class="mt-2">
          <small class="font-semibold">
            {{
              this.$t('NoFutureInvoices')
            }}
          </small>
        </div>

        <div class="inline-flex mt-2">
          <feather-icon
            v-if="isActiveDedicatedNumber && isToCancelNumber"
            icon="ClockIcon"
            class="feather feather-clock h-5 w-5 mr-1"/>
          <small class="font-semibold">
            {{ invoiceMsgByAppServiceItemStatus }}
          </small>
        </div>

        <div v-if="!isToCancelNumber">
          <small v-if="isActiveDedicatedNumber"
            class="font-semibold">
            {{
              $t('ForPrice', {
                price: $options.filters.dollar(appServiceItemPrice),
              }) | lowercase
            }}
          </small>
        </div>
      </div>

      <div class="flex justify-center">
        <vs-chip
          class="text-lg font-semibold"
          :color="colorByAppServiceItemStatus">
          {{ $t(`$Enums.Tenants.APP_SERVICE_ITEM_STATUS.${appServiceItemStatus}`)}}
        </vs-chip>
      </div>
    </div>

    <vs-divider/>

    <div class="text-center">
      <vs-button
        size="small"
        @click="onClickAction()">
        {{ textButtonActionByAppServiceItemStatus }}
      </vs-button>

      <vs-button
        v-if="isActiveDedicatedNumber"
        class="text-center mt-4 bg-danger"
        type="cancel-flat"
        size="small"
        @click="confirmCancelOrDontCancelSubscription()"  >
        <label v-if="!isToCancelNumber">{{ this.$t('CancelSubscriptionBtnText') }}</label>
        <label v-else>{{ this.$t('DontCancelSubscriptionBtnText') }}</label>

      </vs-button>
    </div>

    <update-payment-method-modal
      v-model="showUpdatePaymentMethodModal"
      :payment-methods="paymentMethods"
      :payment-method="appServiceItemPaymentMethod"
      :caller-id="callerId"
      @updated="$emit('updated-payment-method')"/>
  </vx-card>
</template>

<script>
// Components
import UserMyPurchasesDedicatedNumbersUpdatePaymentMethodModal
from '@/views/modules/user/user-purchases/user-my-purchases/my-dedicated-numbers/UserMyPurchasesDedicatedNumbersUpdatePaymentMethodModal.vue';

// Services
import tenantService from '@/api/modules/tenant.service';

/**
 * Dedicated number card info
 *
 * @module views/modules/user/user-purchases/user-my-purchases/my-dedicated-numbers/UserMyPurchasesDedicatedNumbersCardInfo
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-prop {Array<Object>} paymentMethods - tenant payment methods
 * @vue-prop {Object} appServiceItem - app service item of dedicated number
 * @vue-prop {Object} callerId - caller id associated
 * @vue-data {string} [selectedPaymentMethod=''] - id of selected payment method
 * @vue-data {boolean} [showUpdatePaymentMethodModal=false] - Indicate if show update payment method modal
 * @vue-computed {string} billingLabel - billing label to show
 * @vue-computed {string} appServiceItemStatus - app service item status
 * @vue-computed {Date || null} appServiceItemExpireIn - app service item expire in date
 * @vue-computed {number || null} appServiceItemPrice - app service item price
 * @vue-computed {boolean} canUpdatePaymentMethod - indicate if payment method ca be updated
 * @vue-computed {string} colorByAppServiceItemStatus - color by app service item status
 * @vue-computed {string} textButtonActionByAppServiceItemStatus - text button actions by status
 * @vue-computed {string} invoiceMsgByAppServiceItemStatus - invoice msg to show
 * @vue-computed {Object} appServiceItemPaymentMethod - The payment method of app service item
 * @vue-computed {boolean} appServiceItemPaymentMethodIsTheDefault - indicate if the payment method is the default
 * @vue-computed {Object} appServiceItemPaymentMethodCard - the card of the payment method
 * @vue-computed {string} appServiceItemPaymentMethodBrand - The card brand of payment method
 * @vue-computed {string} appServiceItemPaymentMethodLast4 - last 4 digits of card
 * @vue-computed {string} cardBrandIcon - the card brand icon
 * @vue-event {void} onClickAction - called on click button action
 */
export default {
  name: 'UserMyPurchasesDedicatedNumbersCardInfo',
  i18n: {
    messages: {
      en: {
        NextInvoiceMsg: 'Next invoice on {date}',
        ToCancelSoonMsg: 'Cancels on {date}',
        FirstPaymentMissing: 'First payment is missing',
        FirstPaymentExpired: 'First payment is expired',
        PastDuePayment: 'Past due payment',
        PaymentExpired: 'Expired payment',
        ForPrice: 'For {price}',
        NoFutureInvoices: 'No future invoices',
        TitleCancelDedicatedNumberSubscription: 'Confirm cancel dedicated number',
        TextCancelDedicatedNumberSubscription: 'Are you sure you want to cancel your subscription to this dedicated number? You can still use this number until the date when the subscription ends. No more invoices or charges will be generated.',
        TitleDontCancelDedicatedNumberSubscription: 'Confirm dont cancel dedicated number',
        TextDontCancelDedicatedNumberSubscription: 'Are you sure to dont cancel this dedicated number',
        TitleSuccessCancelDedicatedNumber: 'Dedicated number will be cancel',
        TextSuccessCancelDedicatedNumber: 'The dedicated number will be cancel at the end of the period',
        TitleSuccessDontCancelDedicatedNumber: 'Dedicated number will not be cancel',
        TextSuccessDontCancelDedicatedNumber: 'The dedicated number will not be cancel at the end of the period',
        UpdatePaymentMethodBtnText: 'Update payment method',
        CancelSubscriptionBtnText: 'Cancel subscription',
        DontCancelSubscriptionBtnText: 'Don’t cancel subscription',

      },
    },
  },
  components: {
    UpdatePaymentMethodModal: UserMyPurchasesDedicatedNumbersUpdatePaymentMethodModal,
  },
  props: {
    paymentMethods: {
      type: Array,
      required: true,
    },
    appServiceItem: {
      type: Object,
      required: true,
    },
    callerId: {
      type: Object,
      required: true,
    },
    dedicatedNumberSelected: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      selectedPaymentMethod: '',
      showUpdatePaymentMethodModal: false,
    };
  },
  computed: {
    billingLabel() {
      return `${this.$t('$General.Billing')} ${this.$t(`$Enums.Tenants.APP_SERVICE_ITEM_BILLING_INTERVAL.${this.appServiceItem.billingInterval}`)}`;
    },
    appServiceItemStatus() {
      return this.appServiceItem && this.appServiceItem.status
        ? this.appServiceItem.status
        : '';
    },
    appServiceItemExpireIn() {
      return this.appServiceItem && this.appServiceItem.expireIn
        ? this.appServiceItem.expireIn
        : null;
    },
    appServiceItemPrice() {
      return this.appServiceItem && this.appServiceItem.price
        ? this.appServiceItem.price
        : null;
    },
    canUpdatePaymentMethod() {
      switch (this.appServiceItemStatus) {
        case this.$enums.Tenants.APP_SERVICE_ITEM_STATUS.ACTIVE:
        case this.$enums.Tenants.APP_SERVICE_ITEM_STATUS.INCOMPLETE:
        case this.$enums.Tenants.APP_SERVICE_ITEM_STATUS.PAST_DUE:
          return true;

        default:
          return false;
      }
    },
    colorByAppServiceItemStatus() {
      switch (this.appServiceItemStatus) {
        case this.$enums.Tenants.APP_SERVICE_ITEM_STATUS.ACTIVE:
          return 'success';
        case this.$enums.Tenants.APP_SERVICE_ITEM_STATUS.INCOMPLETE:
        case this.$enums.Tenants.APP_SERVICE_ITEM_STATUS.PAST_DUE:
          return 'warning';
        case this.$enums.Tenants.APP_SERVICE_ITEM_STATUS.INCOMPLETE_EXPIRED:
        case this.$enums.Tenants.APP_SERVICE_ITEM_STATUS.EXPIRED:
          return 'danger';

        default:
          return '';
      }
    },
    textButtonActionByAppServiceItemStatus() {
      switch (this.appServiceItemStatus) {
        case this.$enums.Tenants.APP_SERVICE_ITEM_STATUS.ACTIVE:
          return this.$t('UpdatePaymentMethodBtnText');
        case this.$enums.Tenants.APP_SERVICE_ITEM_STATUS.INCOMPLETE:
        case this.$enums.Tenants.APP_SERVICE_ITEM_STATUS.PAST_DUE:
          return this.$t('$General.Pay');
        case this.$enums.Tenants.APP_SERVICE_ITEM_STATUS.INCOMPLETE_EXPIRED:
        case this.$enums.Tenants.APP_SERVICE_ITEM_STATUS.EXPIRED:
        case this.$enums.Tenants.APP_SERVICE_ITEM_STATUS.CANCELED:
          return this.$t('$General.Resubscribe');

        default:
          return '';
      }
    },
    invoiceMsgByAppServiceItemStatus() {
      if (this.appServiceItemStatus === this.$enums.Tenants.APP_SERVICE_ITEM_STATUS.ACTIVE
      && this.appServiceItem.toCancel === true) {
        return this.$t('ToCancelSoonMsg', {
          date: this.$options.filters.date_format(this.appServiceItemExpireIn, 'MMM DD, YYYY'),
        });
      }

      switch (this.appServiceItemStatus) {
        case this.$enums.Tenants.APP_SERVICE_ITEM_STATUS.INCOMPLETE:
          return this.$t('FirstPaymentMissing');
        case this.$enums.Tenants.APP_SERVICE_ITEM_STATUS.INCOMPLETE_EXPIRED:
          return this.$t('FirstPaymentExpired');
        case this.$enums.Tenants.APP_SERVICE_ITEM_STATUS.ACTIVE:
          return this.$t('NextInvoiceMsg', {
            date: this.$options.filters.date_format(this.appServiceItemExpireIn, 'MMM DD, YYYY'),
          });
        case this.$enums.Tenants.APP_SERVICE_ITEM_STATUS.PAST_DUE:
          return this.$t('PastDuePayment');
        case this.$enums.Tenants.APP_SERVICE_ITEM_STATUS.EXPIRED:
          return this.$t('PaymentExpired');

        default:
          return '';
      }
    },
    appServiceItemPaymentMethod() {
      return this.paymentMethods.find(
        (pm) => pm.id === this.appServiceItem.stripeDefaultPaymentMethodId,
      ) || null;
    },
    appServiceItemPaymentMethodIsTheDefault() {
      return this.appServiceItemPaymentMethod
        && this.appServiceItemPaymentMethod.defaultPaymentMethod;
    },
    appServiceItemPaymentMethodCard() {
      return this.appServiceItemPaymentMethod && this.appServiceItemPaymentMethod.card
        ? this.appServiceItemPaymentMethod.card
        : null;
    },
    appServiceItemPaymentMethodBrand() {
      return this.appServiceItemPaymentMethodCard && this.appServiceItemPaymentMethodCard.brand
        ? this.appServiceItemPaymentMethodCard.brand
        : '';
    },
    appServiceItemPaymentMethodLast4() {
      return this.appServiceItemPaymentMethodCard && this.appServiceItemPaymentMethodCard.last4
        ? this.appServiceItemPaymentMethodCard.last4
        : '';
    },
    cardBrandIcon() {
      switch (this.appServiceItemPaymentMethodBrand) {
        case this.$enums.StripeEnums.CardBrands.DINERS_CLUB:
          return 'diners-club';
        case this.$enums.StripeEnums.CardBrands.UNION_PAY:
        case this.$enums.StripeEnums.CardBrands.UNKNOWN:
          return 'credit-card';

        default:
          return this.appServiceItemPaymentMethodBrand;
      }
    },
    isActiveDedicatedNumber() {
      return this.appServiceItem.status === this.$enums.Tenants.APP_SERVICE_ITEM_STATUS.ACTIVE;
    },
    isCanceledDedicatedNumber() {
      return this.appServiceItem.status === this.$enums.Tenants.APP_SERVICE_ITEM_STATUS.CANCELED;
    },
    isToCancelNumber() {
      return this.appServiceItem.toCancel;
    },
    dedicatedNumberSelectedColor() {
      if (this.dedicatedNumberSelected) return 'background-color: rgba(var(--vs-primary), 0.2)';

      return '';
    },
  },
  methods: {
    onClickAction() {
      switch (this.appServiceItemStatus) {
        case this.$enums.Tenants.APP_SERVICE_ITEM_STATUS.ACTIVE:
          this.showUpdatePaymentMethodModal = true;
          break;
        case this.$enums.Tenants.APP_SERVICE_ITEM_STATUS.INCOMPLETE:
        case this.$enums.Tenants.APP_SERVICE_ITEM_STATUS.PAST_DUE:
        case this.$enums.Tenants.APP_SERVICE_ITEM_STATUS.INCOMPLETE_EXPIRED:
        case this.$enums.Tenants.APP_SERVICE_ITEM_STATUS.EXPIRED:
        case this.$enums.Tenants.APP_SERVICE_ITEM_STATUS.CANCELED:
          this.$emit('pay');
          break;

        default:
      }
    },
    confirmCancelOrDontCancelSubscription() {
      if (!this.appServiceItem.toCancel) {
        this.$showConfirmDialog({
          type: 'confirm',
          color: 'danger',
          title: this.$t('TitleCancelDedicatedNumberSubscription'),
          text: this.$t('TextCancelDedicatedNumberSubscription'),
          accept: () => this.cancelOrDontCancelDedicatedNumberSelected(),
          acceptText: this.$t('$General.Confirm'),
          cancelText: this.$t('$General.Cancel'),
        });
      } else {
        this.$showConfirmDialog({
          type: 'confirm',
          color: 'danger',
          title: this.$t('TitleDontCancelDedicatedNumberSubscription'),
          text: this.$t('TextDontCancelDedicatedNumberSubscription'),
          accept: () => this.cancelOrDontCancelDedicatedNumberSelected(),
          acceptText: this.$t('$General.Confirm'),
          cancelText: this.$t('$General.Cancel'),
        });
      }
    },
    async cancelOrDontCancelDedicatedNumberSelected() {
      this.$vs.loading({ type: 'radius' });

      await tenantService.cancelOrDontCancelDedicatedNumberSubscription(this.callerId._id, !this.appServiceItem.toCancel);

      if (this.appServiceItem.toCancel) {
        this.appServiceItem.toCancel = false;
      } else {
        this.appServiceItem.toCancel = true;
      }

      this.$vs.loading.close();

      if (this.appServiceItem.toCancel) {
        this.$showSuccessActionNotification({
          title: this.$t('TitleSuccessCancelDedicatedNumber'),
          text: this.$t('TextSuccessCancelDedicatedNumber'),
        });
      } else {
        this.$showSuccessActionNotification({
          title: this.$t('TitleSuccessDontCancelDedicatedNumber'),
          text: this.$t('TextSuccessDontCancelDedicatedNumber'),
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>

.vx-col {
  .vx-card {
    height: 100%;
  }
}

</style>
