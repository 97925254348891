<template>
  <vx-card
    no-shadow
    card-border
    class="h-full flex justify-center items-center">
    <div class="text-center">
      <a
        href="#"
        class="block"
        @click.prevent="$emit('add')">
        <feather-icon
          icon="PlusIcon"
          class="p-3 inline-flex rounded-full text-primary"
          svgClasses="h-16 w-16"
          :style="{background: 'rgba(var(--vs-primary),.15)'}">
        </feather-icon>
      </a>

      <a
        href="#"
        class="mt-5 link-popup block"
        @click.prevent="$emit('add')">
        {{ addNewText }}
      </a>
    </div>
  </vx-card>
</template>

<script>
/**
 * Add new item card conponent
 *
 * @module views/_components/AddNewItemCard
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 *   @vue-prop {string} icon - icon to show
 *   @vue-prop {string} text - text to show
 *   @vue-computed {string} addNewText - add new text to show
 */
export default {
  name: 'AddNewItemCard',
  i18n: {
    messages: {
      en: {
        AddNewDefaultText: 'Add new',
      },
    },
  },
  props: {
    icon: {
      type: String,
      required: false,
      default: 'PlusIcon',
    },
    text: {
      type: String,
      required: false,
      default: '',
    },
  },
  computed: {
    addNewText() {
      return this.text || this.$t('AddNewDefaultText');
    },
  },
};
</script>

<style scoped>

</style>
